@import './variables.scss';


.vote-company-list {

    .list-group-item {
        border:1px solid #FDE0D9;
        border-radius:7px;
        padding:12px;

        &:hover {
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
        }

        .companyLogo {
            background-color: #feebdc;
            border-radius: 8px;
            overflow: hidden;
    
            img {
                border-radius: 50%;
            }
        }
    }

    
}