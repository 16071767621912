.flipClock {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
}
  
  .flipUnitContainer {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    perspective-origin: 50% 50%;
    perspective: 300px;
    background-color: #FEEBDC;
    border-radius: 3px;
    box-shadow: 0px 10px 10px -10px grey; }
  
  .upperCard, .lowerCard {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    }
    .upperCard span, .lowerCard span {
      font-size: 26px;
      font-weight: 600;
      color: #333333; }
  
  .upperCard {
    align-items: flex-end;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .upperCard span {
      transform: translateY(50%); }
  
  .lowerCard {
    align-items: flex-start;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
    .lowerCard span {
      transform: translateY(-50%); }
  
  .flipCard {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    backface-visibility: hidden; }
    .flipCard span {
      font-size: 26px;
      font-weight: 600;
      color: #333333; }
    .flipCard.unfold {
      top: 50%;
      align-items: flex-start;
      transform-origin: 50% 0%;
      transform: rotateX(180deg);
      background-color: #FEEBDC;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: 0.5px solid black;
     }
      .flipCard.unfold span {
        transform: translateY(-50%); }
    .flipCard.fold {
      top: 0%;
      align-items: flex-end;
      transform-origin: 50% 100%;
      transform: rotateX(0deg);
      background-color: #FEEBDC;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 0.5px solid #feebdc;
      border-bottom: 0.5px solid black; }
      .flipCard.fold span {
        transform: translateY(50%); }
  
  .fold {
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d; }
  
  .unfold {
    animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d; }
  
  @keyframes fold {
    0% {
      transform: rotateX(0deg); }
    100% {
      transform: rotateX(-180deg); } }
  
  @keyframes unfold {
    0% {
      transform: rotateX(180deg); }
    100% {
      transform: rotateX(0deg); } }
  