@import '../variables.scss';

.mds-new-post {
    margin-bottom: 120px;

    .card {
        border-color: #DA552F;
        border-radius: 12px;
        box-shadow: 0px 20px 40px rgba(34, 34, 34, 0.08);
        background-color: transparent;
        cursor: pointer;
        
       
    }
    
    

}