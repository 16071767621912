@import './variables.scss';



.flash {
    animation-name: animation-flash;
    animation-duration: .5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}


@keyframes animation-flash {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}