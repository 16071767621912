$mds-color-primary:#F66640;
$mds-color-primary-text:#222222;
$mds-color-primary-light:#FEE7D6;
$mds-color-disabled:#FAA084;
$mds-color-card-strokes:#FDDECC;
$mds-color-hero-section:#FEEBDC;
$mds-color-page-bg:#FFF7EA;
$mds-color-muted-text:#888888;
$mds-color-hero-section-headers: #111111;
$mds-color-dark:#222222;
$mds-color-secondary:#444444;
$mds-color-twitter:#1C93E4;
$mds-color-linkedin:#0077B5;

$mds-border-radius:8px;