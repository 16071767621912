.onboarding-page {
    background: url("../../assets/Illustrations/footerSignup.png") no-repeat fixed left bottom;
}


.auth-screen {
    @media (min-width: 992px) {
        padding-top: 120px !important;
    }
}

body {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (min-width: 992px) {
        background-image: none !important;
    }
}