
@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import './mds.module.scss';
@import './nav.module.scss';
@import './searchResultPage.module.scss';
@import './community/new-post.scss';
@import './vote.scss';
@import './awards.scss';
@import './footer.scss';
@import './onboarding.scss';
@import './slick.min.scss';
@import './animation.scss';
@import './fonts.scss';
@import './youtube-modal.scss';
@import './flip-clock.scss';



html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif,Poppins;
}

* {
  box-sizing: border-box;
}


select::-ms-expand {
  display: none;
}

h1{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-size: 64px;
  font-weight: 800;
}

@media (max-width: 992px) { 
  h1 {font-size:45px;} /*1rem = 16px*/
  
}

h2{
font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 800  ;
font-size: 38px;
color: #111111;
}

@media (max-width: 992px) { 
  h2 {font-size:28px;font-weight: 800;} /*1rem = 16px*/
}


h3{
  font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 60px;
}

h4{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

h5 {
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

h6 {
  font-family: 'Poppins',sans-serif;
}

p{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px; 
}

li{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px; 
}

form{
  font-family: 'Poppins',sans-serif;
}

a {
  text-decoration: none;
  font-family: 'Poppins',sans-serif;
}

Button {
  font-family: 'Poppins',sans-serif;
}

Button:focus{
  /* background-color: black !important; */
  box-shadow: none !important;
}

Button:active{
  box-shadow: none !important;
}

button {
  font-family: 'Poppins',sans-serif;

}

input:focus, textarea:focus, select:focus{
  outline: none;
}

