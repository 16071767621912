$assetPath :"../assests/Illustrations";


.award-calender-card-live {
    background-color: $mds-color-primary-light;
    border-radius: 5px;
    margin: 3px;
}

.award-calender-card-disable {
    background-color: $mds-color-page-bg;
    border: 1px solid #FDE0D9;
    margin: 3px;
    border-radius: 5px;
}

.award-calender-card-no-data{
padding: 3px;
}

.award-calender-card-no-data-inner-item{
    background-color: $mds-color-page-bg;
    border: 1px solid #FDE0D9;
    border-radius: 5px;
}


.awards-calender-card-live-date-text {
    color: #DA552F;
    font-family: "Dancing Script";
    font-style: normal;
}

.awards-calender-card-disable-date-text {
    font-family: "Dancing Script";
    font-style: normal;
    text-transform: capitalize;
    // color: #DA552F;
}

.awards-calender-card-disable-heading-text {
    font-size: 12px;
    color: $mds-color-muted-text;
}

.awards-calender-card-live-heading-text {
    font-size: 14px;
}

.awards-calender-card-live-small-text {
    font-size: 11px;
}

.awards-calender-card-disable-small-text {
    font-size: 11px;
}

.winner-card {
    // padding-left: 50px;
    height: fit-content;
    padding-top: 20px;

    @media (min-width: 992px) {
    }
}

.winner-card-company-name {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
}


.winner-card-category-name {
    color: $mds-color-primary;
}


.mds-header-plus-accordian {


    .accordion-button:not(.collapsed) {
        background-color: #fff7ea;
        color: black;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("../../public/minus.svg");

    }

    .accordion-button {
        background-color: #fff7ea;
    }

    .accordion-button::after {
        background-image: url("../../public/plus.svg");
        background-color: #fff7ea
    }

    .accordion-button {
        background-color: #fff7ea;
    }

    .accordion-button::after {
        background-image: url("../../public/plus.svg");
        background-color: #fff7ea
    }

}

.accordion-item {
    border: 1px solid #FDE0D9;
}


.accordion-body {
    background-color: #fff7ea;
}

@media (min-width: 992px) {
    .mds-awards-jury-section {
        background-color: #FEEBDC;
    }
}

@media (max-width: 992px) {
    .mds-awards-category-section {
        background-color: #FEEBDC;
    }
}

@media (max-width: 992px) {
    .mds-subcategory-card {
        background-color: #fff7ea;
    }
}

.mds-text-sm-white {
    @media (max-width: 992px) {
        color: white !important;
    }
}


.mds-award-winner-logo {
    
    background-color :#FDE0D0;
    border-radius : 4.8px;
    

    @media (min-width: 992px) {
        padding: 30px;
    }

    @media (max-width: 992px) {
        width : 48px; 
        height : 48px;
    }

    & * {
        width: 24px !important;
        height: 24px !important;   

        @media (min-width: 992px) {
            
        width: 100px !important;
        height: 100px !important;
        }
    }
}

.winner-row {
    @media (min-width: 992px) {
        --bs-gutter-x : 120px;
    }
}