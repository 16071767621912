@import './variables.scss';

.mds-footer {
    .social-icons {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}