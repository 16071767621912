@import './variables.scss';


.mds-search-result-page-item > div {
    padding: 8px;
    cursor : pointer;
    border-bottom : 1px solid #FDDAC8;
}

.mds-search-result-page-item > div:hover {
    border-radius: 8px;
    border-bottom-color: #FEEBDC;
    background-color: #FEEBDC;
}

.mds-search-result-page-item:last-child > div {
    border-bottom:none;
}

@media (min-width: 992px) {
    .mds-search-result-page-item:nth-last-child(2):nth-child(odd) > div
     {
        border-bottom:none;
    }
}
