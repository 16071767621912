@import "./variables.scss";

body {
  * {
    font-family: "Poppins";
  }

  a:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.no-focus-outline {
  outline: none !important;
}

.mds-form {
  .form-label {
    font-weight: 500;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  .form-control {
    border: 1px solid rgba(253, 224, 217, 1);
    border-radius: 8px;
    background-color: transparent !important;
    box-shadow: none !important;

    &.is-invalid {
      border-color: red;
    }

    &:focus {
      // box-shadow: 0 0 0 0.25rem rgba($mds-color-primary, 25%);
      border: 1px solid rgb(253, 224, 217);
      box-shadow: none !important;
    }

    @media (min-width: 992px) {
      box-shadow: none !important;

      &:focus {
        // box-shadow: 0 0 0 0.25rem rgba($mds-color-primary, 25%);
        border: 1px solid rgba(253, 224, 217, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04) !important;
      }
      &.shadow-none {
        border: none !important;
        box-shadow: none !important;
      }
    }

    &::placeholder {
      color: $mds-color-muted-text;
    }
  }
}

.mds-form-white {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  .form-control {
    border-color: #fde0d9 !important;
    background-color: #fff7ea !important;
  }
}

.mds-form-control-white {
  border-color: #fde0d9 !important;
  background-color: #fff7ea !important;
  &:focus {
    box-shadow: none;
  }
}

.mds-form-dropdown {
  border: 1px solid rgba(253, 224, 217, 1);
  border-radius: 8px;

  .selected-text {
    color: $mds-color-muted-text;
  }

  &.selected {
    .selected-text {
      color: $mds-color-primary-text;
    }
  }

  .btn-toggle-icon {
    color: $mds-color-primary;
    background-color: $mds-color-primary-light;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 4px;
  }
}

.mds-cur-pointer {
  cursor: pointer;
}

.mds-text-primary {
  color: $mds-color-primary !important;
}

.mds-text-dark {
  color: $mds-color-dark !important;
}

.mds-text-secondary {
  color: $mds-color-secondary !important;
}

.mds-text-muted {
  color: $mds-color-muted-text !important;
}

.mds-text-primary-light {
  color: $mds-color-primary-light !important;
}

.mds-text-primary-muted {
  color: $mds-color-card-strokes !important;
}

.mds-text-page-bg {
  color: $mds-color-page-bg !important;
}

.mds-mb-5 {
  margin-bottom: 120px;
}

.mds-fw-none {
  font-weight: normal;
}

.mds-fw-xs {
  font-weight: 300;
}

.mds-fw-sm {
  font-weight: 400;
}

.mds-fw-md {
  font-weight: 500;
}

.mds-fw-lg {
  font-weight: 600;
}

.mds-fw-xl {
  font-weight: 700;
}

.mds-fw-xxl {
  font-weight: 900;
}

.mds-lg-fw-xxl {
  @media (min-width: 992px) {
    font-weight: 900;
  }
}

.w-md-100 {
  @media (max-width: 992px) {
    width: 100%;
  }
}

.w-cover {
  width: 400px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.w-cover-mw {
  min-width: 400px;
  @media (max-width: 992px) {
    min-width: 0px;
    width: 100%;
  }
}

.mds-fs-p {
  font-size: 18px !important;
}

.mds-fs-4 {
  font-size: 16px !important;
}

.mds-fs-5 {
  font-size: 14px !important;
}

.mds-fs-sm {
  font-size: 12px !important;
}

.mds-fs-md {
  font-size: 22px !important;
}

.mds-fs-lg {
  font-size: 24px !important;
}

.mds-fs-xl {
  font-size: 40px !important;
}

.mds-lg-fs-xl {
  @media (min-width: 992px) {
    font-size: 40px !important;
  }
}

.mds-fs-xxl {
  font-size: 48px !important;
}

@media (min-width: 992px) {
  .mds-lg-gx-5 {
    --bs-gutter-x: 120px;
  }
}

.mds-rounded {
  border-radius: 8px !important;
}

.mds-rounded-1 {
  border-radius: 6px !important;
}

.mds-rounded-sm {
  border-radius: 4px !important;
}

.mds-rounded-md {
  border-radius: 10px !important;
}

.mds-rounded-lg {
  border-radius: 12px !important;
}

.mds-rounded-xl {
  border-radius: 16px !important;
}

.mds-rounded-1 {
  border-radius: 5px !important;
}

.mds-bg-secondary {
  background-color: $mds-color-secondary;
}

.mds-bg-primary {
  background-color: $mds-color-primary !important;
}

.mds-bg-primary-light {
  background-color: $mds-color-primary-light !important;
}

.mds-bg-primary-stroke {
  background-color: $mds-color-card-strokes !important;
}

.mds-bg-primary-light-sm-only {
  @media (max-width: 992px) {
    background-color: $mds-color-primary-light !important;
  }
}

.mds-bg-white {
  background-color: $mds-color-page-bg !important;
}

.mds-bg-hover-primary-muted {
  &:hover {
    background-color: $mds-color-hero-section !important;
  }
}

.mds-bg-primary-muted {
  background-color: $mds-color-hero-section !important;
}

.mds-bg-primary-muted-sm-only {
  @media (max-width: 992px) {
    background-color: $mds-color-hero-section !important;
  }
}

.mds-bg-muted {
  background-color: $mds-color-muted-text !important;
}

.mds-bg-card-primary {
  background-color: rgb(253, 222, 204) !important;
}

.mds-bg-lg-card-primary {
  @media (min-width: 992px) {
    background-color: rgb(253, 222, 204) !important;
  }
}

.mds-lg-bg-transparent {
  @media (min-width: 992px) {
    background-color: transparent !important;
  }
}

.mds-border-radius {
  border-radius: $mds-border-radius !important;
}

.mds-border-radius-lg {
  border-radius: 12px !important;
}

.mds-border-0 {
  border: none;
}

.mds-border-muted {
  border: 1px solid $mds-color-card-strokes;
}

.mds-border-muted-bottom {
  border-bottom: 1px solid $mds-color-card-strokes;
}

.mds-border-lg-only {
  @media (min-width: 992px) {
    border: 1px solid $mds-color-primary;
  }
}

.mds-border {
  border-color: #fddac8 !important;
}

.mds-border-primary {
  border-color: $mds-color-primary !important;
}

.mds-border-white {
  border-color: $mds-color-page-bg !important;
}

.mds-border-primary-light {
  border-color: $mds-color-card-strokes !important;
}

.mds-border-primary-light-lg-only {
  @media (min-width: 992px) {
    border-color: $mds-color-card-strokes !important;
  }
}

.mds-nav-tabs {
  .nav-link {
    color: $mds-color-muted-text !important;
    font-weight: 300;
  }

  .nav-link:hover {
    color: $mds-color-primary-text !important;
  }

  .nav-link.active {
    color: $mds-color-primary !important;
    background-color: transparent;
    border-bottom: 4px solid $mds-color-primary;
    border-radius: 0;
    font-weight: 500;
  }
}

.mds-text-primary-dark {
  color: $mds-color-primary-text;
}

.mds-btn-icon {
  width: 24px;
  height: 24px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($mds-color-primary, 8%);
  border-radius: 4px;

  &.active {
    color: white !important;
    background-color: $mds-color-primary !important;
  }
}

.mds-btn {
  font-size: 18px;
  border-radius: 8px !important;
}

.mds-btn-sm {
  font-size: 14px;
  border-radius: 8px !important;
}

.mds-px-lg {
  padding-left: 80px;
  padding-right: 80px;
}

.mds-btn-primary {
  background-color: $mds-color-primary;
  color: $mds-color-page-bg;

  &:hover {
    color: white;
    background-color: darken($mds-color-primary, 15%);
  }

  &:active {
    background-color: darken($mds-color-primary, 20%);
  }
}

.mds-btn-light-primary {
  background-color: $mds-color-hero-section;
  color: $mds-color-primary;
  border: none !important;

  &:hover {
    color: darken($mds-color-primary, 15%);
    background-color: $mds-color-hero-section;
  }

  &:active {
    color: darken($mds-color-primary, 20%);
    background-color: $mds-color-hero-section;
  }

  &.active {
    color: white;
    background-color: $mds-color-hero-section;
    border: 1px solid $mds-color-primary;
  }
}

.mds-btn-light-primary:focus,
.show > .mds-btn-light-primary.dropdown-toggle {
  color: white;
  background-color: $mds-color-primary;
  border: none;
}

.mds-btn-outline-primary {
  background-color: transparent;
  color: $mds-color-primary;
  border-color: $mds-color-primary !important;

  &:hover {
    color: darken($mds-color-primary, 15%);
    border-color: darken($mds-color-primary, 15%);
  }

  &:active,
  &.active {
    color: white;
    background-color: $mds-color-primary;
    border-color: $mds-color-primary;
  }
}

.mds-btn-outline-twitter {
  background-color: transparent;
  color: $mds-color-twitter;
  border-color: $mds-color-twitter !important;

  &:hover {
    color: darken($mds-color-twitter, 15%);
    border-color: darken($mds-color-twitter, 15%);
  }

  &:active,
  &.active {
    color: white;
    background-color: $mds-color-twitter;
    border-color: $mds-color-twitter;
  }
}

.mds-btn-outline-linkedin {
  background-color: transparent;
  color: $mds-color-linkedin;
  border-color: $mds-color-linkedin !important;

  &:hover {
    color: darken($mds-color-linkedin, 15%);
    border-color: darken($mds-color-linkedin, 15%);
  }

  &:active,
  &.active {
    color: white;
    background-color: $mds-color-linkedin;
    border-color: $mds-color-linkedin;
  }
}

.mds-btn-outline-primary-light {
  background-color: transparent !important;
  color: $mds-color-primary;
  border-color: rgba($mds-color-primary, 30%) !important;

  &:hover {
    color: darken($mds-color-primary, 15%);
    border-color: darken(rgba($mds-color-primary, 30%), 15%);
  }

  &:active,
  &.active {
    color: white !important;
    background-color: $mds-color-primary !important;
    border-color: $mds-color-primary !important;
  }
}

.mds-btn-outline-primary-white {
  background-color: $mds-color-page-bg !important;
  color: $mds-color-primary;
  border-color: rgba($mds-color-primary, 30%) !important;

  &:hover {
    color: darken($mds-color-primary, 15%);
    border-color: darken(rgba($mds-color-primary, 30%), 15%);
  }

  &:active,
  &.active,
  &.show > .mds-btn-outline-primary-white.dropdown-toggle {
    color: white !important;
    background-color: $mds-color-primary !important;
    border-color: $mds-color-primary !important;
  }
}

.mds-btn-transparent-primary {
  background-color: transparent !important;
  color: $mds-color-primary;
  border-color: none !important;
}

.mds-row-cols-7 > * {
  flex: 0 0 auto;
  width: 14.28%;
}

.mds-card {
  border-color: rgba(253, 224, 217, 1) !important;
  background-color: transparent !important;
  border-radius: $mds-border-radius;

  .card-header {
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    margin-top: 20px !important;
  }
}

.mds-breadcrumb {
  .breadcrumb-item {
    color: rgba($mds-color-primary, 60%);
    a {
      color: rgba($mds-color-primary, 60%);
      &:hover {
        color: rgba($mds-color-primary, 100%);
      }
    }

    &.active {
      color: rgba($mds-color-primary, 100%);
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: $mds-color-primary;
    content: var(--bs-breadcrumb-divider, ">");
  }
}

.mds-dropdown {
  border-radius: 8px;

  &.btn-group > .btn {
    border-radius: 8px;
  }

  &.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  &.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.btn-group > :not(.btn-check) + .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-menu {
    box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
    border-radius: 8px;
    border-color: #fde0d9;
    hr {
      color: #fde0d9;
    }

    .dropdown-item:hover {
      background-color: #feebdc;
    }

    .dropdown-item:active,
    .dropdown-item.active {
      background-color: $mds-color-primary;
    }
  }
}

.mds-no-dropdown-icon::after {
  display: none;
}

.mds-shadow {
  box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
  border-radius: 8px;
  border-color: $mds-color-card-strokes;
}

.mds-shadow-1 {
  box-shadow: 0px 4px 8px rgba(34, 34, 34, 0.08);
}

.mds-no-shadow,
.mds-no-shadow:hover,
.mds-no-shadow:focus {
  box-shadow: none !important;
}

.mds-shadow-lg-only {
  @media (min-width: 992px) {
    box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
    border-radius: 8px;
    border-color: $mds-color-card-strokes;
  }
}

.mds-shadow-hover {
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
    border-color: $mds-color-card-strokes;
  }
}

.mds-popover {
  @extend .mds-shadow;
  border: 1px solid $mds-color-card-strokes;
  background-color: $mds-color-page-bg;

  .popover-arrow::before {
    border-top-color: $mds-color-card-strokes;
  }

  .popover-arrow::after {
    border-top-color: $mds-color-page-bg;
  }
}

.jury-item > a {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #fddac8;
}

.jury-item > a:hover,
.jury-item > a:focus {
  border-bottom: 1px solid #fddac8;
}

.jury-item:last-child > a {
  border-bottom: none;
}

@media (min-width: 992px) {
  .jury-item:nth-last-child(-n + 3) > a {
    border-bottom: none;
  }
}

.mds-pagination {
  .page-item {
    .page-link {
      border-radius: 2px !important;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      border-color: #fddac8;
      color: $mds-color-primary-text;
    }

    &.active {
      .page-link {
        color: white;
        background-color: $mds-color-primary;
        border-color: $mds-color-primary;
      }
    }

    &.disabled {
      .page-link {
        color: $mds-color-muted-text;
      }
    }
  }
}

.modal-backdrop.show {
  background: #222222;
  opacity: 0.2 !important;
}

.mds-modal-shadow {
  box-shadow: 0px 4px 20px rgba(34, 34, 34, 0.12);
}

.mds-editor {
  .ProseMirror {
    min-height: 120px;
  }
}

.mds-list-group-flush {
  .list-group-item {
    border-color: $mds-color-card-strokes !important;
    background-color: transparent !important;
  }
}

.mds-profile-border-radius {
  border-radius: 30px 0px;
}

.mds-table-striped > thead > tr > *,
.mds-table-striped > tbody > tr > *,
.mds-table-striped > tbody {
  border: none !important;
}

.mds-table-striped > thead > tr > *,
.mds-table-striped > tbody > tr > * {
  padding: 20px;
}

.mds-table-striped thead tr * {
  background-color: $mds-color-hero-section;
}

.mds-table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff7ea;
}

.mds-table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: #fff1e3;
}

.mds-table-striped > tbody > tr:nth-of-type(even) > *:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mds-table-striped > tbody > tr:nth-of-type(even) > *:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mds-table-sticky {
  tbody > tr > * {
    z-index: 1;
  }
  tbody > tr > *:first-child,
  thead > tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  // thead th {
  //     position: sticky;
  //     top: 0;
  // }
}

.mds-input-group {
  border: 1px solid $mds-color-card-strokes;
  border-radius: 8px;

  .input-group-text,
  input,
  input:focus {
    background-color: transparent;
    border: none;
  }

  input {
    &:focus {
      box-shadow: none;
    }
  }
}

.mds-list-group {
  .list-group-item {
    background-color: transparent;
    border: 1px solid $mds-color-card-strokes;
    border-radius: 8px;
  }
}

.mds-checkbox input {
  border-color: $mds-color-card-strokes;
}

.mds-checkbox input,
.mds-checkbox input:checked,
.mds-checkbox input:focus {
  width: 20px;
  height: 20px;
  box-shadow: none;
}

.mds-checkbox input:checked {
  background-color: $mds-color-primary;
  border-color: $mds-color-primary;
}

.mds-list-group-flush {
  .list-group-item {
    border-color: $mds-color-card-strokes !important;
    background-color: transparent !important;
  }
}

.mds-text-ellipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.mds-text-ellipsis-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.mds-image-overlay {
  background-image: linear-gradient(
    to bottom,
    rgba($color: #000000, $alpha: 0),
    rgba($color: #000000, $alpha: 0.7)
  );
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

.amazing-people-cover {
  width: 100%;
  min-height: 250px;
  @media (min-width: 992px) {
    max-width: 450px;
  }
}

/* Handle */
div.mds-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
    // height: 2em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mds-color-primary;
    border-radius: 3px;
  }
}

.mds-rounded-icons {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.mds-rounded-icons-lg {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.jobs-card-hover {
  background-color: $mds-color-primary;
  color: $mds-color-page-bg;

  &:hover {
    color: white;
    background-color: darken($mds-color-primary, 15%);
  }

  &:active {
    background-color: darken($mds-color-primary, 20%);
  }

  @media (max-width: 768px) {
    background-color: transparent !important;
    color: $mds-color-primary;
    border-color: none !important;
  }
}

.mds-input-required-field::after {
  content: "*";
  color: rgba($mds-color-primary, 100%);
}

.mds-summit-nav-btn {
  background: linear-gradient(260.91deg, #5675E2 3.42%, #38D748 49.29%, #4089F6 100%);
  background-clip: text;
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.mds-pointer-none {
  pointer-events: none;
}

.mds-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.mds-transition {
  transition: 0.5s ease-in;
}

.mds-grayscale100 {
  filter: grayscale(80%);
}

.mds-grayscale0 {
  filter: grayscale(0%);
}

.mds-summit-footer-image {
  max-width: 100%;

  @media (min-width: 700px) {
    max-width: 409px;
  }
}

.mds-summit-panel-image {
  width: 100%;

  @media (min-width: 992px) {
      width: 544px;
  }
}