@import './variables.scss';


.mdsNavbar {
    font-family: 'Poppins', sans-serif;

    .navbar-brand {
        margin-right: 64px;
    }



    .search-group {
        background-color: #FFF7EA;
        border-color: #FDC2AB !important;
        border-radius: 8px;


        .input-group-text {
            color: $mds-color-primary;
            padding-right: 0px;
            padding-left: 0px;
        }

        .search-input {
            background-color: transparent;

        }

        .search-input:focus {
            outline: none;
            box-shadow: none;
        }

        .clear-btn {
            background-color: #FEEBDC;
            color: #F66640;
            outline: none;
            border: none;
            border-radius: 8px !important;
            width: 24px;
            height: 24px;
            padding-right: 0px;
            padding-left: 0px;
        }

        .clear-btn:hover {
            color: #F66640;
        }

    }

    .search-group-focused {
        background-color: #FFF7EA !important;
        border-color: #FDDAC8 !important;

        @media (min-width: 992px) {
            box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
        }

    }

    .search-dropdown {
        background-color: #FFF7EA !important;
        border-color: #FDDAC8 !important;
        border-radius: 8px;
        margin-top: 4px;

        @media (min-width: 992px) {
            box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
        }

        .search-result-dropdown-card {
            border-bottom: 1px solid #FDDAC8 !important;
        }

        .search-result-dropdown-card:last-child {
            border-bottom: none !important;
        }

        .search-result-item {
            padding: 8px;
            border-radius: 8px;
        }

        .search-result-item:hover {
            background-color: #FEEBDC;
        }
    }

    .btn {
        color: #444444;
    }

    .btn:hover {
        color: #000000;
    }

    .more-links-dropdown {
        box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
        border-radius: 8px;
        border-color: #FDE0D9;
        left: -100px;

        hr {
            color: #FDE0D9;
        }
        .dropdown-item:hover {

            background-color: #FEEBDC;
            }

        .dropdown-item:active, .dropdown-item.active  {
            background-color: $mds-color-primary;
        }

    }

    .container {
        .offcanvas.show {
            background-color: #FFF7EA;
            width: 100%;
            max-width: 768px;

            .search-group-focused {
                border-radius: 8px !important;
            }

            .search-dropdown {
                background-color: transparent !important;
                border-color: transparent !important;
                border-radius: 0 0 8px 8px !important;
                box-shadow: none !important;
            }

            .mds-navlinks .btn {
                font-size: xx-large;
                font-weight: bold;
            }

            .offcanvas-footer {
                .btn {
                    color: $mds-color-primary;
                    font-size: xx-large;
                    border: 1px solid $mds-color-primary;
                    border-radius: 100%;
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

}